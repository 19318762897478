<template>
	<div class="pc-login-wrap">
		<BannerLateralInicio />
		<div class="pc-login-form">
			<div class="pc-login-form--wrap">
				<LogoTopoInicio />
				<router-view></router-view>
			</div>
			<div class="pc-login-footer">
				<p>Feito com ❤️ por <a href="https://cvcrm.com.br" target="_blank">CV CRM</a></p>
				<a v-if="verificarRotaExiste('perguntas-frequentes')" href="/cliente/perguntas-frequentes" target="_blank"
					>Perguntas Frequentes</a
				>
			</div>
		</div>
	</div>
</template>

<script>
import BannerLateralInicio from "@/layouts/_partials/BannerLateralInicio";
import LogoTopoInicio from "@/layouts/_partials/LogoTopoInicio";

export default {
	name: "MyComponent",
	components: { BannerLateralInicio, LogoTopoInicio },
};
</script>
