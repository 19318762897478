<template>
    <div class="row">
        <div class="col-12">
            <div class="pc-titlePage">
                <div class="pc-titlePage--title">
                    <h1>{{ titulo }}</h1>
                    <p>{{ subtitulo }}</p>
                </div>
                <div class="pc-titlePage--unidade">
                    <HomeButton />
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomeButton from "@/components/HomeButton";
export default {
    props: {
        titulo: { type: String, required: true },
        subtitulo: { type: String, required: true },
    },

    components: {
        HomeButton,
    },
};
</script>
