<template>
	<div>
		<div v-if="avisoConcluido">
			<AvisoAgendamentoConcluido
				:data="this.$route.params.data_agendamento"
				:empreendimento="this.$route.params.empreendimento"
				:horario="this.$route.params.horario"
				:motivo="this.$route.params.motivo"
				:tipo="this.$route.params.tipo"
				:unidade="this.$route.params.unidade"
				:vistoriador="this.$route.params.vistoriador"
			></AvisoAgendamentoConcluido>
		</div>
		<div class="pc-bloco">
			<div class="pc-titleBloco">
				<h3>Agendamentos</h3>
				<div
					class="pull-right pc-title-legenda"
					v-if="agendamentos.total"
				>
					Informações encontradas: {{ agendamentos.total }}
				</div>
			</div>
			<LoadingComponent :isLoading="isLoading" />
			<div
				class="pc-tabela"
				v-if="agendamentos.agendamentos"
			>
				<div class="pc-tabela--row pc-tabela--head">
					<div class="pc-tabela--td pc-f1">
						<p>Data e Hora</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Ano</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Empreendimento</p>
					</div>
                    <div class="pc-tabela--td pc-f1">
						<p>Bloco</p>
					</div>
                    <div class="pc-tabela--td pc-f1">
						<p>Unidade</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Tipo de Agendamento</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Vistoriador</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Situação atual</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Opções</p>
					</div>
				</div>
				<div
					v-for="agendamento in agendamentos.agendamentos"
					class="pc-tabela--row"
				>
					<div class="pc-tabela--td pc-f1">
						<div
							class="pc-tabela--td-data pc-tabela--td-contentIcon"
						>
							<div
								class="pc-tabela--td-contentIcon-icon pc-tabela--td-contentIcon-data"
							>
								<span
									><b>{{
										agendamento.data
											| dateParse("YYYY-MM-DD HH:mm")
											| dateFormat("DD")
									}}</b
									>{{
										agendamento.data
											| dateParse("YYYY-MM-DD HH:mm")
											| dateFormat("MMM")
									}}</span
								>
							</div>
							<p>
								{{
									agendamento.data
										| dateParse("YYYY-MM-DD HH:mm")
										| dateFormat("HH:mm")
								}}
							</p>
						</div>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p data-title="Ano">
							{{
								agendamento.data
										| dateParse("YYYY-MM-DD HH:mm")
										| dateFormat("YYYY")
							}}
						</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p data-title="Empreendimento">
							{{ agendamento.unidade.empreendimento.nome }}
						</p>
					</div>
                    <div class="pc-tabela--td pc-f1">
						<p data-title="Bloco">
							{{ agendamento.unidade.bloco.nome }}
						</p>
					</div>
                    <div class="pc-tabela--td pc-f1">
						<p data-title="Unidade">
							{{ agendamento.unidade.nome }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p data-title="Tipo de Agendamento">
							{{ agendamento.tipo | capitalize }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p
							v-if="agendamento.vistoriador"
							data-title="Vistoriador"
						>
							{{ agendamento.vistoriador }}
						</p>
						<p v-else>--</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p
              data-title="Situação atual"
							class="pc-labelStatus"
							v-bind:style="{
                                color: '#fff',
								background: agendamento.situacao.cor,
							}"
						>
							{{ agendamento.situacao.nome | capitalize }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f1 justify-content-center">
						<div class="pc-multipleLines">
                            <router-link
                                v-if="
                                    agendamento.situacao.sigla === 'RL' &&
                                    agendamento.unidade.empreendimento.permitir_criar_agendamento &&
                                    !agendamento.possuiRevistoria
                                "
                                :to="{
                                    name: 'novo-agendamento',
                                    params: {
                                        idUnidade: agendamento.unidade.idunidade,
                                        idAgendamento: agendamento.idAgendamento,
                                        idVistoria: agendamento.id
                                    },
                                }"
                                class="pc-btnGhost pc-btnGhost-primary"
                            >
                                Agendar
                            </router-link>
                            <div v-if="agendamento.pode_desmarcar">
                                <button
                                    @click="
									alertCancelar(
										agendamento.tipo,
										agendamento.id
									)
								"
                                    class="pc-btnGhost pc-btnGhost-primary"
                                >
                                    Desmarcar
                                </button>
                            </div>
							<div v-if="agendamento.mensagem">
								<br />
								<button
									class="pc-btnGhost pc-btnGhost-large"
									href=""
									@click.prevent="
										openModal(
											agendamento.mensagem.mensagem,
											agendamento.mensagem.usuario,
											agendamento.mensagem.data,
											'Mensagem'
										)
									"
									data-toggle="modal"
									data-target="#modal"
								>
									Mensagem
								</button>
							</div>
							<div v-if="agendamento.comentario">
								<br />
								<button
									class="pc-btnGhost pc-btnGhost-large"
									href=""
									@click.prevent="
										openModal(
											agendamento.comentario.mensagem,
											agendamento.comentario.usuario,
											agendamento.comentario.data,
											'Comentário'
										)
									"
									data-toggle="modal"
									data-target="#modal"
								>
									Comentário
								</button>
							</div>
						</div>
					</div>
				</div>
				<PaginateInput
					:total="agendamentos.total"
					:limit="paginacao.limit"
					:offset="paginacao.offset"
					@paginacao="montarListagem"
				/>
			</div>
			<div v-else>Nenhuma informação encontrada.</div>
			<div
				class="modal fade"
				id="modal"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalTitle"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<h5>Feito por:</h5>
							<div>{{ this.modalFeitoPor }}</div>
							<h5>Data:</h5>
							<div>{{ this.modalData }}</div>
							<h5>Mensagem:</h5>
							<div v-html="modalTexto"></div>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								Fechar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getAgendamentos,
	cancelarAgendamentos,
} from "@/services/AgendamentosService";
import PaginateInput from "@/components/PaginateInput";
import LoadingComponent from "@/components/LoadingComponent";
import getSafe from "@/plugins/OptionalChain";
import IconEye from "@/components/icons/IconEye";
import IconClose from "@/components/icons/IconClose";
import AvisoAgendamentoConcluido from "@/pages/agendamentos/components/AvisoAgendamentoConcluido";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	props: ["idUnidade"],
	emits: ["error"],
	components: {
		AvisoAgendamentoConcluido,
		IconClose,
		IconEye,
		LoadingComponent,
		PaginateInput,
	},
	data() {
		return {
			agendamentos: [],
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
			modalTexto: null,
			modalFeitoPor: null,
			modalData: null,
			avisoConcluido: false,
		};
	},
	beforeMount() {
		this.montarListagem(1);
		if (this.$route.params.avisoConcluido) {
			this.avisoConcluido = true;
		}
	},
	methods: {
		getSafe,
		openModal(conteudo, usuario, data, botao) {
			this.modalTexto = conteudo;
			this.modalFeitoPor = usuario;
			this.modalData = data;

			this.trakingMixpanel("Meus Agendamentos", botao, true, false);
		},
		montarListagem(pagina) {
			console.log(this.paginacao.limit)
			let  offset = (pagina - 1) * this.paginacao.limit;
			offset = isNaN(offset) ? 0 : offset;

			this.isLoading = true;
			getAgendamentos(
				offset,
				this.paginacao.limit,
				this.idUnidade
			)
				.then((response) => {
					this.agendamentos = response.data;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		alertCancelar(tipo, id) {
			if (confirm("Deseja cancelar esse agendamento?")) {
				if (tipo == "Visita Financiamento") {
					tipo = "visita-financiamento";
				}
				this.isLoading = true;
				this.trakingMixpanel(
					"Meus Agendamentos",
					"Desmarcar",
					true,
					true
				);
				cancelarAgendamentos(tipo, id)
					.then((response) => {
						this.$emit("exibirAviso", {
							exibir: true,
							tipo: "sucesso",
							mensagem:
								"Seu agendamento foi cancelado com sucesso.",
						});
						this.montarListagem();
					})
					.catch((error) => {
						this.$emit("error", error.response.data);
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},
		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Meus Agendamentos",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},

	filters: {
		capitalize: function(value) {
			if (!value) return "";
			value = value.toString();
			var words = value.split(" ");
			for (let index = 0; index < words.length; index++) {
				words[index] =
					words[index].charAt(0).toUpperCase() +
					words[index].slice(1);
			}
			return words.join(" ");
		},
	},
};
</script>
