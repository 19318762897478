export default {
    CONCLUIDO_PESSOA(state) {
        state.concluido.pessoa = true;
    },

    CONCLUIDO_UNIDADES(state) {
        state.concluido.unidades = true;
    },

    CONCLUIDO_TERMOS(state) {
        state.concluido.termos = true;
    },

    CONCLUIDO_PERMISSOES(state) {
        state.concluido.permissoes = true;
    },

    CONCLUIDO_PERMISSOES_SINDICO(state) {
        state.concluido.permissoesSindico = true;
    },

    PADRAO_REDIRECIONAR(state) {
        state.concluido.pessoa = false;
        state.concluido.unidades = false;
        state.concluido.termos = false;
        state.concluido.permissoes = false;
        state.concluido.permissoesSindico = false;
    },

    SESSAO_EXPIRADA(state, rota) {
        state.sessaoExpirada = true;
        state.rotaRedirecionar = rota;
    },

    RESETAR_SESSAO(state) {
        state.sessaoExpirada = false;
        state.primeiroAcessoHome = true;
        state.rotaRedirecionar = null;
    },

    HOME_VISUALIZADA(state) {
        state.primeiroAcessoHome = false;
    },

    MENSAGEM_LOGIN(state, mensagem) {
        state.mensagemLogin = mensagem;
    },
}
