<template>
  <div class="pc-bloco">
    <Aviso
      :exibir="alerta.exibir"
      :mensagem="alerta.mensagem"
      :tipo="alerta.tipo"
    />
    <div class="pc-titleBloco">
      <h3>Formulário de Assistência</h3>
    </div>

    <LoadingComponent :isLoading="isLoading"/>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(enviarFormulario)"
        class="center-block"
      >
        <div class="row">
          <div class="col-lg-6 col-12" v-if="formEmpreendimentoLocalidade">
            <div class="form-group">
              <SelectInput
                label="nome"
                :titulo= "'Empreendimento ou Localidade'"
                :options="EmpreendimentoLocalidade"
                :clearable="false"
                :valor.sync="empreendimentoOuLocalidadeSelecionado"
                :placeholder="'Selecione Empreendimento ou Localidade'"
                @update:valor="alterouEmpreendimentoLocalidade"
                regras="required"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" id="div_localidade" v-if="formLocalidade">
            <div class="form-group">
              <SelectInput
                ref="localidadeComponent"
                titulo="Localidade"
                :options="localidades"
                :reduce="(option) => option.idlocalidade"
                :valor.sync="formulario.localidade.idlocalidade"
                :placeholder="
                  localidades.length > 0
                    ? 'Selecione uma Localidade'
                    : 'Nenhuma Localidade ativa no sistema'
                "
                :regras="selecionouLocalidade ? 'required' : ''"
              />
            </div>
          </div>
          <div class="col-12" id="div_descricao_localidade" v-if="formDescricaoLocalidade">
            <div class="control-group">
              <ValidationProvider
                :rules="selecionouLocalidade ? 'required' : ''"
                v-slot="{ errors }"
              >
                <label class="control-label" for="descricao">
                  <strong>*Descrição</strong>
                </label>
                <VueEditor
                  v-model="formulario.localidade.descricao"
                  :placeholder="placeHolderText"
                  :editorToolbar="customToolbar"
                />
                <label class="error">
                  <span>{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12 " id="div_idempreendimento" v-if="formEmpreendimento"> 
            <div class="form-group">
              <SelectInput
                label="nome"
                titulo="Empreendimento"
                ref="empreendimentoComponent"
                :reduce="(option) => option.idempreendimento"
                :options="empreendimentos"
                :valor.sync="formulario.idempreendimento"
                :placeholder="
                  empreendimentos.length > 0
                    ? 'Selecione um Empreendimento'
                    : 'Nenhum Empreendimento ativo no sistema'
                "
                @update:valor="escolheuEmpreendimento"
                :regras="selecionouEmpreendimento ? 'required' : ''"
              />
            </div>
          </div>
          <div
            class="col-lg-6 col-12"
            id="div_unidade_area"
            v-if="formUnidadeArea"
          >
            <div class="form-group">
              <SelectInput
                ref="unidadeAreaComponent"
                :titulo="'Unidade ou Área comum'"
                :options="OpcoesUnidadeArea"
                :clearable="true"
                :valor.sync="UnidadeAreaEscolhido"
                :placeholder="'Selecione Unidade ou Área comum'"
                @update:valor="alterouUnidadeArea"
                :regras="selecionouEmpreendimento ? 'required' : ''"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" id="div_idunidade" v-if="formUnidade">
            <div class="form-group">
              <SelectInput
                ref="unidadeComponent"
                titulo="Unidade"
                :options="unidades"
                :reduce="(option) => option.idunidade"
                :valor.sync="formulario.unidade.idunidade"
                :placeholder="placeHolderUnidade"
                :regras="selecionouUnidadeSistema ? 'required' : ''"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" id="div_idarea" v-if="formArea">
            <div class="form-group">
              <SelectInput
                ref="areaComumComponent"
                titulo="Área Comum"
                :options="areas"
                :reduce="(option) => option.idarea"
                :valor.sync="formulario.area.idarea"
                :placeholder="
                  areas.length > 0
                    ? 'Selecione uma Área Comum'
                    : 'Nenhuma área comum ativa no sistema'
                "
                regras="required"
              />
            </div>
          </div>
        </div>
        <div class="row mb-20">
          <div class="col-12" id="div_descricao_area" v-if="formDescricaoArea">
            <div class="control-group">
              <ValidationProvider
                  :rules="(selecionouArea) && !selecionouLocalidade ? 'required' : ''"
                  v-slot="{ errors }"
              >
                <label class="control-label" for="descricao">
                  <strong>*Descrição</strong>
                </label>
                <VueEditor
                  v-model="formulario.area.descricao"
                  :placeholder="placeHolderText"
                  :editorToolbar="customToolbar"
                />
                <label class="error">
                  <span>{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <span>
          <strong>*Itens</strong>
        </span>
        <br/>

        <div class="mt-20" v-if="itens.length > 0">
          <ul class="list-flex -column4 list-checkbox-assistencias">
            <li v-for="item in itens" v-bind:key="item.iditem">
              <label v-bind:for="item.iditem">
                <input
                  type="checkbox"
                  v-bind:value="item.iditem"
                  v-bind:name="'itens'"
                  v-on:click="abrirObservacaoItem(item.iditem, $event)"
                />
                <span>{{ item.nome }}</span>
              </label>
              <br/>
              <div class="hidden" v-bind:id="'divAvisoGarantia_'+ item.iditem" v-if="item.descricao_garantia">
                <div :class="`pc-alert pc-warning closeButton`">
                    <IconAlert />
                    <p>{{ item.descricao_garantia }}</p>
                </div>
              </div>
              <textarea
                class="hidden w-90"
                v-bind:id="'div_descricao_item_' + item.iditem"
                placeholder="Descrição "
                v-on:blur="
                  atribuirDescricaoItem(item.iditem, $event.target.value)
                "
                ref="item.iditem"
              ></textarea>
            </li>
          </ul>
        </div>
        <div v-else>Por favor aguarde...</div>
        <br/>
        <div class="row">
          <div class="col-12 btnEnviar">
            <button
              :disabled="acessoComo"
              name="btSend"
              type="submit"
              value="Enviar"
              class="btn-form pc-btnBox pc-btnBox-secondary"
            >
              Solicitar Assistência</button
            >&nbsp;
            <router-link
              :to="{ name: 'assistencias' }"
              class="btn-form pc-btnGhost"
            >
              <span>Cancelar</span>
            </router-link>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { getUnidades } from "@/services/UnidadeService";
import {
  cadastrarAssistencia,
  retornarLocalidades,
  retornarAreaComum,
  retornarItensManutencao,
} from "@/services/AssistenciasService";
import { retornarDadosSindico } from "@/services/SindicosService";
import SelectInput from "@/components/SelectInput";
import Aviso from "@/components/Aviso";
import LoadingComponent from "@/components/LoadingComponent";
import { VueEditor } from "vue2-editor";
import { retornarEmpreendimentosPessoa } from "@/services/EmpreendimentoService";
import { mapState, mapGetters } from "vuex";
import { objetoTraking } from "@/plugins/Utils";
import Vue from "vue";

export default {
  computed: {
    ...mapState({
      unidadesCliente: (state) => state.unidades.unidades,
      configuracoes: (state) => state.configuracoes.configuracoes,
    }),
    ...mapGetters({
      verificarPermissao: "verificarPermissao",
      acessoComo: "getAcessoComo",
      acessoSindico: "getAcessoSindico",
    }),
  },

  created() {
    this.$mixpanel.track(
      Vue.prototype.$eventoMixPanel,
      objetoTraking(
        "Minhas Assistências",
        "Nova Assistência",
        "Formulário",
        true,
        false
      )
    );
  },

  components: {
    SelectInput,
    LoadingComponent,
    VueEditor,
    Aviso,
  },

  data() {
    return {
      formEmpreendimentoLocalidade: false,
      formEmpreendimento: false,
      formDescricaoLocalidade: false,
      formLocalidade: false,
      formUnidadeArea: false,
      formUnidade: false,
      formArea: false,
      formDescricaoArea: false,
      formDescricaoItem: false,
      formItens: false,

      empreendimentos: [],
      areas: [],
      localidades: [],
      itens: [],
      unidades: [],
      empreendimentoOuLocalidadeSelecionado: null,
      UnidadeAreaEscolhido: null,
      isLoading: false,
      selecionouLocalidade: false,
      selecionouEmpreendimento: false,
      selecionouArea: false,
      selecionouUnidadeSistema: false,
      placeHolderUnidade:
        " -- Selecione um Empreendimento antes de escolher uma unidade -- ",
      placeHolderText: "Descreva com riqueza de detalhe a sua solicitação.",
      alerta: {
        exibir: false,
        mensagem: null,
        tipo: null,
      },
      formulario: {
        idempreendimento: null,
        itens: {},
        unidade: {
          idunidade: null,
          descricao: null,
        },
        area: {
          idempreendimento: null,
          idarea: null,
          descricao: null,
        },
        localidade: {
          idlocalidade: null,
          descricao: null,
        },
      },
      EmpreendimentoLocalidade: ["Empreendimento", "Localidade"],
      OpcoesUnidadeArea: ["Unidade", "Área comum"],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
      ],
    };
  },

  beforeMount() {
    if (this.configuracoes.assistencias.exibir_localidade) {
        this.formEmpreendimentoLocalidade = true;
    } else {
       this.alterouEmpreendimentoLocalidade('Empreendimento');
    }

    if (this.acessoSindico) {
      retornarDadosSindico()
        .then((result) => {
          this.empreendimentos = result.data.empreendimentos_associados;
        })
        .catch((error) => {});
    } else {
      retornarEmpreendimentosPessoa()
      .then((response) => {
          this.empreendimentos = this.verificarEmpreendimentosPermissao(
              response.data
          );
      })
      .catch((error) => {});
    }

    retornarAreaComum()
      .then((response) => {
        let areas = [];
        for (let elementoArea of response.data.areas) {
          let area = {
            label: `${elementoArea.nome}`,
            idarea: elementoArea.idarea,
          };
          areas.push(area);
        }
        if (!this.acessoSindico) {
          this.areas = areas;
          if (this.areas.length === 1) {
            this.formulario.idarea = this.areas[0].idarea;
          }
        }
      })
      .catch((error) => {});

    retornarItensManutencao()
      .then((response) => {
        let itens = [];
        for (let elementoItem of response.data) {
          let item = {
            nome: `${elementoItem.nome}`,
            iditem: elementoItem.iditem,
            descricao_garantia: elementoItem.descricao_garantia,
            tempo_garantia: elementoItem.tempo_garantia
          };
          itens.push(item);
        }
        this.itens = itens;
        if (this.itens.length === 1) {
          this.formulario.iditem = this.itens[0].iditem;
        }
      })
      .catch((error) => {});
  },

  methods: {
    enviarFormulario() {
      if (this.acessoComo) {
        return;
      }
      let seleciouItem = false;
      document.querySelectorAll("input[type=checkbox]").forEach((e) => {
        if (e.checked) seleciouItem = true;
      });

      if (!seleciouItem) {
        alert("É necessário selecionar pelo menos um item!");
        return;
      }
      this.isLoading = true;
      this.trakingMixpanel("Nova Assistência", "Criar Assistência", true, true);

      let arrayForm = {};
      if (this.formulario.localidade.idlocalidade) {
        arrayForm = { localidade: this.formulario.localidade };
      } else if (this.formulario.unidade.idunidade) {
        arrayForm = { unidade: this.formulario.unidade };
        arrayForm.unidade.descricao = this.formulario.area.descricao;
      } else if (this.formulario.area.descricao||this.acessoSindico) {
        this.formulario.area.idempreendimento = this.formulario.idempreendimento;
        arrayForm = { area: this.formulario.area };
      }
      if (this.formulario.itens) {
        arrayForm = {
          ...arrayForm,
          ...{ itens: this.formulario.itens },
        };
      }

      cadastrarAssistencia(arrayForm)
        .then((response) => {
          this.$router.push({
            name: "assistencia",
            params: { idAssistencia: response.data.idassistencia },
          });
        })
        .catch((error) => {
          this.alerta = {
            exibir: true,
            mensagem: "Erro interno ao tentar cadastrar a assistência.",
            tipo: "erro",
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    verificarEmpreendimentosPermissao(empreendimentos) {
      let emprPermitidos = [];
      for (let empreendimento of empreendimentos) {
        if (!empreendimento.permitir_criar_assistencia) {
          continue;
        }
        emprPermitidos.push(empreendimento);
      }
      return emprPermitidos;
    },

    alterouEmpreendimentoLocalidade(opcao) {
      this.resetFormulario(opcao);
      if (opcao === 'Localidade') {
        this.retornarLocalidade();
        this.selecionouLocalidade = true;
        this.formLocalidade = true;
        this.formDescricaoLocalidade = true;
      } else if (opcao === 'Empreendimento') {
        this.formEmpreendimento = true;
        this.formDescricaoArea = true;
        this.selecionouEmpreendimento = true;
      }
    },

    resetFormulario(tipo) {
      if (tipo === 'Localidade') {
        this.formulario.idempreendimento = null;
        this.formulario.unidade.idunidade = null;
        this.formulario.area.idarea = null;
        this.formEmpreendimento = false;
        this.formDescricaoArea = false;
        this.selecionouEmpreendimento = false;
      } else if (tipo === 'Empreendimento') {
        this.formulario.localidade.idlocalidade = null;
        this.formLocalidade = false;
        this.formDescricaoLocalidade = false;
        this.selecionouLocalidade = false;
      }
      this.resetUnidadeArea();
    },

    alterouUnidadeArea(opcao) {
      this.resetUnidadeArea();
      if (opcao === 'Unidade' && !this.acessoSindico) {
        this.formUnidade = true;
        this.selecionouUnidadeSistema = true;
      } else if (opcao === 'Área comum') {
        this.retornarAreasComunsEmpreendimentos(this.formulario.idempreendimento);
        this.formArea = true;
        this.selecionouArea = true;
      }
    },

    resetUnidadeArea() {
      this.selecionouArea = false;
      this.formArea = false;
      this.formUnidade = false;
      this.selecionouUnidadeSistema = false;
    },

    retornarLocalidade(){
        retornarLocalidades()
        .then((response) => {
            let localidades = [];
            for (let elementoLocalidade of response.data.localidades) {
            let localidade = {
                label: `${elementoLocalidade.nome}`,
                idlocalidade: elementoLocalidade.idlocalidade,
            };
            localidades.push(localidade);
            }
            this.localidades = localidades;
            if (this.localidades.length === 1) {
            this.formulario.idlocalidade = this.localidades[0].idlocalidade;
            }
        })
        .catch((error) => {});
    },

    escolheuEmpreendimento(idEmpreendimento) {
        this.formulario.unidade.idunidade = null;
        this.formulario.area.idarea = null;
        this.resetUnidadeArea();
        if (idEmpreendimento) {
          getUnidades(idEmpreendimento)
            .then((response) => {
              let unidades = [];
              for (let elemento of response.data) {
                let unidade = {
                  label: `${elemento.bloco.nome} - ${elemento.nome}`,
                  idunidade: elemento.idunidade,
                  data_entrega_chaves: elemento.data_entrega_chaves,
                };
                unidades.push(unidade);
              }
              this.unidades = unidades;
              this.placeHolderUnidade = "-- Selecione uma Unidade --";
            })
            .catch((error) => {});
        } else {
          this.unidades = [];
        }
        this.verificarAcessoSindico();
    },

    verificarAcessoSindico() {
      if (this.acessoSindico) {
        this.alterouUnidadeArea('Área comum');
      } else {
        if (this.configuracoes.assistencias.exibir_area_comum) {
          this.UnidadeAreaEscolhido = null;
          this.formUnidadeArea = true;
        } else {
          this.alterouUnidadeArea('Unidade');
        }
      }
    },

    empreendimentosAtivos(empreendimentos) {
      var empreendimentoAssistencia = [];
      for (let empreendimento of empreendimentos) {
        empreendimentoAssistencia.push(empreendimento);
      }
      return empreendimentoAssistencia;
    },

    abrirObservacaoItem(iditem, event) {
      let item = document.getElementById("div_descricao_item_" + iditem);
      let itemGarantia = document.getElementById("divAvisoGarantia_" + iditem);
      if (event.target.checked) {
        item.classList.remove("hidden");
        itemGarantia?.classList.remove("hidden");
        this.formulario.itens[iditem] = { iditem: iditem };

        if (this.formulario.unidade.idunidade) {
            const item = this.itens.find(item => item.iditem === iditem);
            const dataAtual = new Date();
            let dataGarantia;
            const empreendimento = this.empreendimentos.find(
              empreendimento => empreendimento.idempreendimento === this.formulario.idempreendimento
            );

            if (empreendimento.inicio_garantia === 'habite_se') {
              if (empreendimento.data_entrega) {
                dataGarantia = new Date(empreendimento.data_entrega);
              }
            } else {
              if (unidade.data_entrega_chaves) {
                const unidade = this.unidades.find(unidade => unidade.idunidade === this.formulario.unidade.idunidade);
                dataGarantia = new Date(unidade.data_entrega_chaves);
              }
            }

            if (dataGarantia != null) {
              dataGarantia.setMonth(dataGarantia.getMonth() + item.tempo_garantia);
              if (dataGarantia < dataAtual) {
                item.descricao_garantia = 'Item fora da garantia';
              }
            }
          }
      } else {
        item.classList.add("hidden");
        itemGarantia?.classList.add("hidden");
        item.value = "";
        delete this.formulario.itens[iditem];
      }
    },

    atribuirDescricaoItem(iditem, descricao) {
      this.formulario.itens[iditem]["descricao"] = descricao;
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Minhas Assistências", acao, subacao, intencao, ateracao)
      );
    },

    retornarAreasComunsEmpreendimentos(idEmpreendimento) {
      retornarAreaComum(idEmpreendimento)
        .then((response) => {
          let areas = [];
          for (let elementoArea of response.data.areas) {
            var area = {
              label: `${elementoArea.nome}`,
              idarea: elementoArea.idarea,
            };
            areas.push(area);
          }
          this.areas = areas;
          if (this.areas.length === 1) {
            this.formulario.idarea = this.areas[0].idarea;
          }
          this.formDescricaoArea = true;
          this.formArea = true;
          this.selecionouArea = true;
        })
        .catch((error) => {});
    },
  },

};
</script>

<style lang="css">
.endereco {
  padding-top: 10px;
  border-top: 1px dashed #d8d8d8;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}

.with-top-padding {
  padding-top: 10px;
}

.hidden {
  display: none;
}

.w-90 {
  width: 90%;
}

.list-checkbox-assistencias {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: initial;
  column-count: 4;
  column-gap: 5px;
  width: 100%;
  margin-left: 0px;
}

.list-checkbox-assistencias li {
  width: 32% !important;
}

@media (max-width: 769px) {
  .list-checkbox-assistencias {
    display: flex;
    flex-direction: column;
  }
  .list-checkbox-assistencias li {
    width: 100% !important;
  }
}

.list-checkbox-assistencias li {
  display: inline-block; 
  width: 100%; 
  margin-bottom: 5px;
}

.scrollable-content {
  overflow-y: auto;
}
</style>
