<template>
	<div class="pc-content">
		<BreadCrumbs :caminho="caminho" />
		<TituloPagina titulo="Meus Atendimentos" subtitulo="Relação de atendimentos da sua(s) unidade(s)">
			<router-link v-if="permissaoCriarAtendimento" :to="{ name: 'novoAtendimento' }"
				class="pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary">
            Solicitar novo atendimento</router-link>
		</TituloPagina>
		<div class="vld-parent">
			<LoadingComponent :isLoading="isLoading" />

			<Aviso :exibir="aviso.exibir" :tipo="aviso.tipo" :mensagem="aviso.mensagem" />

			<div class="row">
				<div class="col-12">
					<div class="pc-bloco">
						<div class="pc-titleBloco">
							<div>
								<h3>Atendimentos</h3>
							</div>
							<p
								class="pc-title-legenda"
								v-if="paginacao.total > 0"
							>Informações encontradas: {{ paginacao.total }}</p>
						</div>
						<div v-if="paginacao.total > 0">
							<div class="pc-tabela">
								<div class="pc-tabela--row pc-tabela--head">
									<div class="pc-tabela--td pc-f1">
										<p>Protocolo</p>
									</div>
									<div class="pc-tabela--td pc-f1">
										<p>Data de cadastro</p>
									</div>
									<div class="pc-tabela--td pc-f2">
										<p>Assunto</p>
									</div>
									<div class="pc-tabela--td pc-f2">
										<p>Subassunto</p>
									</div>
									<div class="pc-tabela--td pc-f2">
										<p>Empreendimento</p>
									</div>
                                    <div class="pc-tabela--td pc-f2">
										<p>Bloco</p>
									</div>
                                    <div class="pc-tabela--td pc-f2" v-if="acessoSindico">
										<p>Área comum</p>
									</div>
									<div class="pc-tabela--td pc-f2" v-else>
										<p>Unidade(s)</p>
									</div>
									<div class="pc-tabela--td pc-f2">
										<p>Situação atual</p>
									</div>
									<div class="pc-tabela--td pc-f1">
										<p>Visualizar</p>
									</div>
								</div>
								<Listagem
									v-for="atendimento in atendimentos.atendimentos"
									v-bind:key="atendimento.idatendimento"
									:atendimento="atendimento"
								/>
								<PaginateInput
									:total="paginacao.total"
									:limit="paginacao.limit"
									:offset="paginacao.offset"
									@paginacao="setarPagina"
								/>
							</div>
						</div>
						<div v-else>Nenhuma informação encontrada.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Listagem from "@/pages/atendimentos/components/Listagem";
import { getAtendimentos } from "@/services/AtendimentosService";
import UnidadePadrao from "@/components/UnidadePadrao";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";

export default {
	name: "MeusAtendimentos",

	components: {
		BreadCrumbs,
		Listagem,
		LoadingComponent,
		UnidadePadrao,
		PaginateInput,
		TituloPagina,
		Aviso,
	},

	computed: {
		...mapState({
			unidade: (state) => state.unidades.unidade,
			cliente: (state) => state.login.cliente,
			unidades: (state) => state.unidades.unidades
		}),
		...mapGetters({
			acessoSindico: "getAcessoSindico",
			verificarPermissao: "verificarPermissao",
			verificarPermissaoSindico: "verificarPermissaoSindico",
			verificarPermissoes: "verificarPermissoes"
		}),
	},

	created() {
		this.$mixpanel.track(
			Vue.prototype.$eventoMixPanel,
			objetoTraking(
				"Meus Atendimentos",
				"Meus Atendimentos",
				"Listagem",
				true,
				false
			)
		);
		
		if (
			(!this.acessoSindico && (this.unidades == null ||
				(this.verificarPermissao(this.unidade.perfil.sigla, "criar_atendimentos"))) ||
				(this.acessoSindico && this.verificarPermissaoSindico("criar_atendimentos")) ||
				(this.unidades != null && this.verificarPermissoes(this.unidades, "criar_atendimentos")) )
		) {
			this.permissaoCriarAtendimento = true;
		}
	},

	data() {
		return {
			caminho: [
				{
					texto: "Meus Atendimentos",
					ativo: true,
					rota: null,
				},
			],
			atendimentos: [],
			paginacao: {
				total: 0,
				offset: 0,
				limit: 30,
			},
			aviso: {
				exibir: false,
				tipo: null,
				mensagem: null,
			},
			isLoading: false,
			permissaoCriarAtendimento: false,
		};
	},

	beforeMount() {
		this.setarPagina(1);
	},

	methods: {
		setarPagina(pagina) {
			this.isLoading = true;
			getAtendimentos(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit,
			)
				.then((response) => {
					var atendimento = response.data.atendimentos;
					Object.entries(atendimento).forEach(([index, valor]) => {
						let arrayNomes = [];
						Object.entries(valor.unidade.unidades).forEach(([chave, unidade]) => {
							arrayNomes.push(unidade.nome);
						});
						atendimento[index].unidade.nome = arrayNomes.join(" | ");
					});
					this.atendimentos = response.data;
					this.paginacao.total = response.data.total;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.tratarErro(error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		tratarErro(erro) {
			let mensagem =
				"Ocorreu um erro inesperado, por favor tente mais tarde.";
			if (erro.code != 422) {
				mensagem = erro.error;
			}

			this.aviso = {
				exibir: true,
				tipo: "erro",
				mensagem: mensagem,
			};
		},
	},
};
</script>
