const state = {
  configuracoes: {
    menu: 'horizontal',
    cor: {
        primaria: '#409a75',
        secundaria: '#316e66',
    },
    logo: {
        url: null
    },
    tela_login: {
        titulo: null,
        subtitulo: null,
        padrao_reset_senha: null,
        exibir_acesso_sindico: true,
        reset_senha_disponivel: null,
    },
    exibir_menu: {
        agendamentos: true,
        atendimentos: true,
        assistencias: true,
        pesquisas: true,
        portal_beneficios: true,
        previsao_entrega: true,
    },
    mensagem_informacoes_financeiras: null,
    idconfiguracao: '0',
    modulos: {
        agendamentos: true,
        atendimentos: true,
        assistencias: true,
        pesquisas: true,
        sienge: true,
        assistencia_tecnica: true
    },
    plugins: [{
        nome: '',
        link: '',
        icone: 'fa-user',
        tipo: '',
        cliente: 'S',
        gestor: 'S',
        associado: 'S',
        iconepc: '',
    }],
    especifico_cv: {
        ocultar_visualizacao_detalhada_extrato: false,
        cadastro_painel_cliente: true,
        esqueci_senha_painel_cliente: true,
        primeiro_acesso_painel_cliente: true,
        modo_esqueci_senha_painel_cliente: '',
        senha_portal_cliente: {
            regex: null,
            mensagem: null
        },
        retirar_coluna_valor_boleto_sienge: false,
        retirar_coluna_parcelas_extrato_sienge: true,
        exibir_extrato_portal_cliente: false,
        analytics: 'UA-35126492-2',
        exibir_multiplos_portais: false,
    },
    exibir_financeiro: {
        imposto_de_renda: true,
        extrato_financeiro: true,
        boletos: true,
        boletos_reparcelamento: true,
        boletos_cv: true,
        extrato_financeiro_cv: true,
        informe_rendimentos_cv: true,
    },
    assistencias: {
        exibir_localidade: true,
        exibir_area_comum: true,
    },
    ordem_menu: [
        {
            rota: '',
            nome_descricao: '',
            ordem: '',
            idextensao: '',
            tipo: '',
            link: '',
            cliente: 'S',
        }
    ],
    empreendimento: "",
  },
  slug: "default",
};

export default state;
