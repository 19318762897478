import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import { state, mutations } from './default'

import configuracoes from './modules/configuracoes'
import unidades from './modules/unidades'
import termoAceite from './modules/termoAceite'
import sessao from './modules/sessao'
import permissao from './modules/permissao'
import login from './modules/login'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        login,
        configuracoes,
        unidades,
        termoAceite,
        sessao,
        permissao,
    },
    state,
    mutations,
    plugins: [createPersistedState()]
});

export default store
