import DefaultTemplate from "@/layouts/DefaultTemplate";
import PageNotFound from "@/pages/PageNotFound";
import Login from "@/pages/inicio/Login";
import Home from "@/pages/home/Index";
import MeusDados from "../pages/configuracoes/MeusDados";
import ExcluirDados from "../pages/configuracoes/ExcluirDados";
import MeusDadosSindico from "../pages/configuracoes/MeusDadosSindico";
import AndamentoObra from "@/pages/andamentoObra/AndamentoObra";
import InicioTemplate from "@/layouts/InicioTemplate";
import Redirecionando from "@/pages/inicio/Redirecionando";
import Autenticando from "@/pages/inicio/Autenticando";
import AcessarComo from "@/pages/inicio/AcessarComo";
import Termo from "../components/Termo";
import ConfirmarConta from "@/pages/confirmarConta/ConfirmarConta";
import atendimentos from "./modules/atendimentos";
import assistencias from "./modules/assistencias";
import agendamentos from "./modules/agendamentos";
import plugins from "./modules/plugins";
import financeiro from "./modules/financeiro";
import pesquisas from "./modules/pesquisas";
import contaCliente from "./modules/contaCliente";
import contaSindico from "./modules/contaSindico";
import cadastros from "./modules/cadastros";
import documentos from "./modules/documentos";
import contratos from "./modules/contratos";
import unidades from "./modules/unidades";
import perguntasFrequentes from "./modules/perguntasFrequentes";
import portalBeneficios from "./modules/portalBeneficios";
import NovaSenha from "@/pages/inicio/NovaSenha";
import MultiplosPortais from "@/pages/inicio/MultiplosPortais.vue";

const routes = [
  {
    path: "/",
    component: DefaultTemplate,
    children: [
      {
        path: "/cliente/home",
        component: Home,
        name: "home",
        meta: {
          title: "Portal do Cliente - Página Principal",
        },
      },

      {
        path: "/cliente/configuracoes/meus-dados",
        name: "meus-dados",
        component: MeusDados,
        meta: {
          title: "Portal do Cliente - Meus Dados",
        },
      },

      {
        path: "/cliente/configuracoes/meus-dados/excluir-dados",
        name: "excluir-dados",
        component: ExcluirDados,
        meta: {
          title: "Portal do Cliente - Excluir acesso",
        },
      },

      {
        path: "/cliente/configuracoes/meus-dados-sindico",
        name: "meus-dados-sindico",
        component: MeusDadosSindico,
        meta: {
          title: "Portal do Cliente - Meus Dados",
        },
      },

      {
        path: "/cliente/emprendimento/andamento-obra",
        component: AndamentoObra,
        name: "andamento-obra",
        meta: {
          title: "Portal do Cliente - Andamento da Obra",
        },
      },

      ...atendimentos.rotas,
      ...assistencias.rotas,
      ...agendamentos.rotas,
      ...plugins.rotas,
      ...financeiro.rotas,
      ...pesquisas.rotas,
      ...cadastros.rotas,
      ...documentos.rotas,
      ...contratos.rotas,
      ...unidades.rotas,
      ...perguntasFrequentes.rotas,
      ...portalBeneficios.rotas,
    ],
  },
  {
    path: "/",
    component: InicioTemplate,
    children: [
      {
        path: "/cliente/:slug?",
        name: "login",
        component: Login,
        props: true,
        meta: {
          title: "Portal do Cliente - Login",
        },
      },

      {
        path: "/cliente/:slug?/nova-senha/:id/:hash/:modulo?",
        name: "nova-senha",
        component: NovaSenha,
        props: true,
        meta: {
          title: "Portal do Cliente - Nova Senha",
        },
      },

      {
        path: "/cliente/multiplos-portais",
        name: "multiplos-portais",
        component: MultiplosPortais,
        props: true,
        meta: {
          title: "Portal do Cliente - Multiplos Portais",
        },
      },

      ...contaCliente.rotas,
      ...contaSindico.rotas,
    ],
  },
  {
    path: "/cliente/termo-aceite",
    name: "termo-aceite",
    component: Termo,
    meta: {
      title: "Termo Aceite",
    },
  },
  {
    path: "/cliente/confirmar/:hash",
    name: "confirmar-conta",
    component: ConfirmarConta,
    props: true,
    meta: {
      title: "Confirmar Criação de Conta",
    },
  },
  {
    path: "/cliente/redirecionando",
    name: "redirecionando",
    component: Redirecionando,
    props: true,
    meta: {
      title: "Portal do Cliente - Redirecionando",
    },
  },
  {
    path: "/cliente/autenticacao/:origem",
    name: "autenticando",
    component: Autenticando,
    props: true,
    meta: {
      title: "Portal do Cliente - Autenticando",
    },
  },
  {
    path: "/cliente/:slug/acessar-como/:idpessoa/:sessionId",
    name: "acessarComo",
    component: AcessarComo,
    props: true,
    meta: {
      title: "Portal do Cliente - Acessar Como",
    },
  },
  {
    /*
     * Deixar esta rota sempre por último
     */
    path: "*",
    component: PageNotFound,
    children: [
      {
        path: "*",
        name: "not-found",
        component: PageNotFound,
        meta: {
          title: "Portal do Cliente - Página não encontrada",
        },
      },
    ],
  },
];

export default routes;
