<template>
    <vue-dropzone
        v-on:vdropzone-success-multiple="sucessoArquivos"
        v-on:vdropzone-error-multiple="erroArquivos"
        id="dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
    >
    </vue-dropzone>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import store from "../store";
import { URL_API } from "@/bootstrap/index";

export default {
    name: "Dropzone",

    props: {
        uniqid: { type: String, required: true },
    },

    components: {
        vueDropzone,
    },

    data() {
        return {
            sucesso: null,
            dropzoneOptions: {
                url: `${URL_API}/api/v1/cliente/${this.$route.meta.slugDropZone}/upload-arquivos`,
                method: "post",
                maxFilesize: 25,
                maxFiles: 5,
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                parallelUploads: 5,
                autoQueue: true,
                addRemoveLinks: true,
                autoProcessQueue: false,
                uploadMultiple: true,
                acceptedFiles: ".jpg, .jpeg, .gif, .png, .pdf, .txt, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .mov, .mkv, .avi",
                params: {
                    uniqid: this.uniqid,
                },
                headers: {
                    Authorization: `Bearer ${store.state.login.access_token}`,
                },
                dictDefaultMessage: "Arraste os arquivos aqui para upload "+
                " <br>Arquivos Suportados: JPG,JPEG,GIF,PNG,PDF,TXT,DOC,DOCX,XLS,XLSX,PTT,PPTX,MP4,MOV,MKV,AVI"+
                "<br> Tamanho Máximo do Arquivo: 25MB",
                dictFallbackMessage:
                    "Seu navegador não suporta arrastar os arquivos",
                dictFallbackText:
                    "Please use the fallback form below to upload your files like in the olden days.",
                dictFileTooBig:
                    "Arquivo muito grande ({{filesize}}MiB). Máximo permitido: {{maxFilesize}}MiB.",
                dictInvalidFileType: "Envie apenas arquivos com extensões: .jpg, .jpeg, .gif, .png, .pdf, .txt, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .mov, .mkv, .avi",
                dictResponseError: "Resposta do servidor {{statusCode}}",
                dictCancelUpload: "Cancelar Upload",
                dictCancelUploadConfirmation:
                    "Você tem certeza que deseja cancelar o upload?",
                dictRemoveFile: "Remover arquivo",
                dictRemoveFileConfirmation: null,
                dictMaxFilesExceeded:
                    "Você não pode fazer upload de mais arquivos",
            },
        };
    },

    methods: {
        quantidadeArquivos() {
          return this.$refs.dropzone.getQueuedFiles().length;
        },
        async enviarArquivos() {
            if (this.$refs.dropzone.getQueuedFiles().length > 0) {
                this.$refs.dropzone.processQueue();
            } else {
                this.$emit("sucesso", true);
            }
        },

        sucessoArquivos(file, response) {
            this.$emit("sucesso", true);
        },

        erroArquivos(files, message, xhr) {
            alert(message);
            this.removerArquivos();
        },

        removerArquivos() {
            this.$refs.dropzone.removeAllFiles(true);
        },
    },
};
</script>
