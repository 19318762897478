<template>
    <ValidationProvider :rules="regras" v-slot="{ errors }">
        <div v-if="showTitle">
            <label v-if="regras.includes('required')">
                <strong>*{{ titulo }}</strong>
            </label>
            <label v-else>{{ titulo }}</label>
        </div>
        <v-select
            :reduce="reduce"
            :options="options"
            :value="valor"
            :label="label"
            :clearable="clearable"
            :placeholder="placeholder"
            :multiple="multiple"
            @input="onChange"
        >
            <template #no-options="{ search, searching, loading }">
                Nenhum dado encontrado.
            </template>
        </v-select>
        <label class="error"
            ><span>{{ errors[0] }}</span></label
        >
    </ValidationProvider>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "SelectInput",

    components: {
        vSelect,
    },

    props: {
        titulo: { type: String, required: true },
        options: { type: Array, required: true },
        valor: { default: null },
        regras: { type: String, default: "" },
        reduce: { type: Function, default: (option) => option },
        label: { type: String, default: "label" },
        clearable: { type: Boolean, default: true },
        placeholder: { default: null },
        showTitle: { default: true },
        multiple: { default: false },
    },

    methods: {
        onChange(valor) {
            this.$emit("update:valor", valor);
        },
        limparSelecao() {
            this.$emit("update:valor", null);
        },
    },
};
</script>
