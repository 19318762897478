<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <div>
        <h3>Dados do Cliente</h3>
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(salvarDados)" class="center-block">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input
                v-model="clienteFormData.nome"
                type="text"
                class="form-control"
                placeholder="Nome"
                id="nome"
                name="nome"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" v-if="cliente.documento_tipo === 'cpf'">
            <div class="form-group">
              <label for="rg">RG</label>
              <input
                v-model="clienteFormData.rg"
                v-mask="'##.###.###-#'"
                type="text"
                class="form-control"
                placeholder="RG"
                id="rg"
                name="rg"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="telefone">Telefone</label>
              <vue-tel-input
                v-model="clienteFormData.telefone"
                v-bind="bindProps"
                id="telefone"
                name="telefone"
              >
              </vue-tel-input>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="celular">Celular</label>
              <vue-tel-input
                v-model="clienteFormData.celular"
                v-bind="bindProps"
                id="celular"
                name="celular"
              >
              </vue-tel-input>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="email"><b>* Email</b></label>
                <input
                  v-model="clienteFormData.email"
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  id="email"
                  name="email"
                  required="true"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="senha"><b>* Senha</b></label>
                <input
                  v-model="clienteFormData.senha"
                  type="password"
                  class="form-control"
                  placeholder="Senha Atual"
                  id="senha"
                  name="senha"
                  required="true"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="nova_senha">Nova Senha</label>
              <input
                v-model="clienteFormData.nova_senha"
                type="password"
                :class="[
                  'form-control',
                  senha_regex && clienteFormData.nova_senha
                    ? {
                        'is-invalid': !senha_regex.test(
                          clienteFormData.nova_senha
                        ),
                      }
                    : '',
                ]"
                placeholder="Nova Senha"
                id="nova_senha"
                name="nova_senha"
              />
              <p v-if="configuracoes.especifico_cv.senha_portal_cliente">
                {{ configuracoes.especifico_cv.senha_portal_cliente.mensagem }}
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="confirma">Confirmar nova senha</label>
              <input
                v-model="clienteFormData.confirma"
                type="password"
                :class="[
                  'form-control',
                  clienteFormData.confirma
                    ? {
                        'is-invalid':
                          clienteFormData.nova_senha !=
                          clienteFormData.confirma,
                      }
                    : '',
                ]"
                placeholder="Confirmar nova senha"
                id="confirma"
                name="confirma"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" v-if="cliente.documento_tipo === 'cpf'">
            <div class="form-group">
              <label for="renda_familiar">Renda Familiar</label>
              <input
                v-model="clienteFormData.renda_familiar"
                @keypress="onlyNumber"
                maxlength="10"
                type="text"
                class="form-control"
                placeholder="Renda Familiar"
                id="renda_familiar"
                name="renda_familiar"
              />
            </div>
          </div>
        </div>
        <FormularioEndereco
          ref="formEndereco"
          @error="avisoErro"
          @sucesso="atualizarPessoa"
        />
        <br />
        <div class="row">
          <div class="col-12 btnEnviar">
            <button
              :disabled="acessoComo"
              name="btSend"
              type="submit"
              value="Alterar"
              class="btn-form pc-btnBox pc-btnBox-secondary"
            >
              Alterar
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { putPessoa } from "@/services/PessoasService";
import FormularioEndereco from "./FormularioEndereco";
import LoadingComponent from "@/components/LoadingComponent";
import { objetoTraking } from "@/plugins/Utils";
import { removerMascaraTelefone } from "@/plugins/Utils";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

export default {
  computed: {
    ...mapGetters({
      cliente: "getCliente",
      configuracoes: "getConfiguracoes",
      acessoComo: "getAcessoComo",
    }),

    ...mapActions(["getMe"]),
  },

  components: {
    FormularioEndereco,
    LoadingComponent,
    VueTelInput,
  },

  data() {
    return {
      isLoading: false,
      senha_regex: null,
      clienteFormData: {
        nome: "",
        rg: "",
        renda_familiar: "",
        telefone: "",
        celular: "",
        email: "",
        senha: "",
        nova_senha: "",
        confirma: "",
      },

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },
    };
  },

  beforeMount() {
    if (this.configuracoes.especifico_cv.senha_portal_cliente) {
      this.senha_regex = new RegExp(
        this.configuracoes.especifico_cv.senha_portal_cliente.regex
      );
    }
    this.clienteFormData.nome = this.cliente.nome;
    this.clienteFormData.telefone = this.cliente.telefone;
    this.clienteFormData.celular = this.cliente.celular;
    this.clienteFormData.email = this.cliente.email;
    this.clienteFormData.rg = this.cliente.rg;
    this.clienteFormData.renda_familiar = this.cliente.renda_familiar;
    this.isLoading = true;
  },

  mounted() {
    this.isLoading = false;
  },

  methods: {
    salvarDados() {
      if (this.acessoComo) {
        return;
      }

      if (this.$refs.formEndereco.verificarForm()) {
        this.$refs.formEndereco.atualizarDados();
      } else {
        this.atualizarPessoa();
      }
    },

    atualizarPessoa() {
      this.isLoading = true;
      this.trakingMixpanel("Alterar dados do cliente", "Alterar", true, true);
      this.clienteFormData.celular = removerMascaraTelefone(
        this.clienteFormData.celular
      );
      this.clienteFormData.telefone = removerMascaraTelefone(
        this.clienteFormData.telefone
      );
      if (this.cliente.documento_tipo === "cnpj") {
        this.removerCamposParaPJ();
      }
      putPessoa(this.clienteFormData)
        .then((response) => {
          let msg =
            "Solicitação de alteração de dados enviada com sucesso, " +
            "aguarde a confirmação para ver seus dados alterados.";
          if (response.data.validar_gestor === false) {
            msg = "Seus dados foram atualizados com sucesso!";
          }

          this.$emit("exibirAviso", {
            exibir: true,
            mensagem: msg,
            tipo: "sucesso",
          });
          this.getMe();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.avisoErro(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    avisoErro(error) {
      let mensagem = "Erro interno, por favor tentar mais tarde.";
      if (error.response.status != 422) {
        mensagem = error.response.data.message;
      }
      this.$emit("exibirAviso", {
        exibir: true,
        tipo: "erro",
        mensagem: mensagem,
      });
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Dados", acao, subacao, intencao, ateracao)
      );
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    removerCamposParaPJ() {
      delete this.clienteFormData.rg;
      delete this.clienteFormData.renda_familiar;
    },
  },
};
</script>
