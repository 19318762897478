<template>
	<div :style="userStyle">
		<router-view></router-view>
	</div>
</template>

<script>
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mapState, mapActions } from "vuex";

export default {
	computed: {
		...mapState({
            slug: (state) => state.configuracoes.slug,
			configuracoes: (state) => state.configuracoes.configuracoes,
			termoAceite: (state) => state.termoAceite.termoAceite,
			mensagemLogin: (state) => state.sessao.mensagemLogin,
		}),
		userStyle() {
			return {
				"--cor-primaria": this.configuracoes.cor.primaria,
				"--cor-secundaria": this.configuracoes.cor.secundaria,
                "--cor-secundaria-10": this.configuracoes.cor.secundaria + "1a",
                "height": "100%",
			};
		},
	},

	mounted() {
		document.body.classList.add(
			`pc-bodyColor`,
			`pc-menu-${this.configuracoes.menu}`
		);

		if (this.mensagemLogin) {
			this.$vToastify.success(this.mensagemLogin, "=)");
			this.limparMensagemLogin();
		}

		//equalHeight
		let windowWidth = window.innerWidth;
		let pcSameHeight = document.querySelectorAll(".pc-sameHeight");

		if (windowWidth > 992) {
			sameHeight();
		}
		window.onresize = sameHeight;

		function sameHeight() {
			let maxHeight = 0;

			if (pcSameHeight !== null) {
				pcSameHeight.forEach(function(elPcSameHeight) {
					if (elPcSameHeight.innerHeight > maxHeight) {
						maxHeight = elPcSameHeight.innerHeight;
					}
				});
				pcSameHeight.forEach(function(elPcSameHeight) {
					elPcSameHeight.innerHeight = maxHeight;
				});
			}
		}

        let currentURL = window.location.href;
        let match = currentURL.match(/\/cliente\/([^\/]+)\/?$/);
        let urlBody = "/api/v1/cliente/white-label-js/body/";
        let urlHead = "/api/v1/cliente/white-label-js/head/";
        let urlCss = "/api/v1/cliente/white-label-css/";
        const scriptHead = document.createElement('script');
        const scriptBody = document.createElement('script');
        const style = document.createElement('style');

        if (this.slug) {
            urlBody += this.slug;
            urlHead += this.slug;
            urlCss += this.slug;
        } else if (match) {
            let slug = match[1];
            urlBody += slug;
            urlHead += slug;
            urlCss += slug;
        }

        scriptHead.src = urlHead;
        document.head.appendChild(scriptHead);
        scriptBody.src = urlBody;
        document.body.appendChild(scriptBody);
        style.innerHTML = `@import url('${urlCss}');`;
        document.head.appendChild(style);
	},

	watch: {
		termoAceite(newValue) {
			if (newValue.termo_pendente) {
				this.$router.push({
					name: "termo-aceite",
				});
			}
		},
	},

	methods: {
		...mapActions(["limparMensagemLogin"]),
	},
};
</script>

<style scoped>
@import url("../assets/css/all.min.css");
</style>

<style lang="css">
.pc-login-form h1 {
	color: var(--cor-primaria);
}
.pc-login-form form p {
	color: var(--cor-primaria);
}
.pc-login-form .pc-btns-Login a.active {
	border-color: var(--cor-secundaria);
}

.pc-btnBox.pc-btnBox-primary {
	background: var(--cor-primaria);
}
.pc-btnBox.pc-btnBox-primary:hover {
	background: var(--cor-secundaria);
}
.pc-btnBox {
	background: var(--cor-secundaria);
}
.pc-btnBox:hover {
	background: var(--cor-primaria);
}

.pc-topo {
	background: var(--cor-primaria);
}
.pc-topo
	.pc-top--content
	.pc-top--controles
	.pc-top--user
	.pc-btnContext:hover
	svg
	circle {
	fill: var(--cor-secundaria);
}
.pc-topo
	.pc-top--content
	.pc-top--controles
	.pc-top--user
	.pc-btnSair:hover
	svg
	path {
	fill: var(--cor-secundaria);
}

.pc-infoIcon .pc-infoIcon--icon {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background: var(--cor-secundaria-10);
}
.pc-infoIcon .pc-infoIcon--icon svg path {
	fill: var(--cor-secundaria);
}
.pc-btnAtalho.active,
.pc-btnAtalho:hover {
	background: var(--cor-primaria);
}
.pc-btnAtalho.active .pc-infoIcon .pc-infoIcon--icon svg path,
.pc-btnAtalho:hover .pc-infoIcon .pc-infoIcon--icon svg path {
	fill: var(--cor-secundaria);
}
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:hover {
	color: var(--cor-secundaria);
}
.pc-btnText:hover {
	color: var(--cor-secundaria);
}
.pc-btnText:hover svg path {
	fill: var(--cor-secundaria);
}
.pc-btnGhost:hover {
	background: var(--cor-primaria);
	border-color: var(--cor-primaria);
}
.pc-grafico .pc-graficoPorcentagem .pc-graficoPorcentagem--circle {
	stroke: var(--cor-secundaria);
}
.pc-grafico .pc-graficoBg .pc-graficoBg--circle {
	stroke: var(--cor-secundaria);
}
.pc-grafico-status-obra .pc-grafico-status-obraPorcentagem .pc-grafico-status-obraPorcentagem--bar {
  fill: var(--cor-secundaria);
}
.pc-grafico-status-obra .pc-grafico-status-obraBg .pc-grafico-status-obraBg--bar {
  fill: var(--cor-secundaria);
}
.pc-btnSelect:hover {
	background: var(--cor-primaria);
}
.pc-timelineObra .pc-timelineObra--dia:before {
	background: var(--cor-secundaria);
}
.pc-timelineObra .pc-timelineObra--dia:after {
	background: var(--cor-secundaria) 10;
}
.pc-timelineObra--fotos
	a.pc-timelineObra--fotosAll
	.pc-timelineObra--fotosAll-info {
	background: var(--cor-primaria) de;
}

.pc-btnBox {
	background: var(--cor-secundaria);
}
.pc-btnBox:hover {
	background: var(--cor-primaria);
}
.pc-btnGhost:hover {
	background: var(--cor-primaria);
	border-color: var(--cor-primaria);
}
.pc-breadcramb ul li a:hover {
	color: var(--cor-secundaria);
}
.pc-statusObra
	.pc-statusObra--item
	.pc-statusObra--item-grafico
	.pc-statusObra--item-porcentagel {
	background: var(--cor-primaria);
}
.pc-galeria .pc-prev svg path {
	fill: var(--cor-primaria);
}
.pc-galeria .pc-prev:hover {
	background: var(--cor-primaria);
}
.pc-galeria .pc-next svg path {
	fill: var(--cor-primaria);
}
.pc-galeria .pc-next:hover {
	background: var(--cor-primaria);
}
.pc-btnBox.pc-btnBox-secundary {
	background: var(--cor-secundaria);
}
.pc-btnBox.pc-btnBox-secundary:hover {
	background: var(--cor-primaria);
}
.pc-btnGhost.pc-btnGhost-primary {
	border-color: var(--cor-primaria);
	color: var(--cor-primaria);
}
.pc-btnGhost.pc-btnGhost-secondary {
	border-color: var(--cor-secundaria);
	color: var(--cor-secundaria);
}
.pc-btnGhost:hover {
	background: var(--cor-secundaria);
	border-color: var(--cor-secundaria);
	color: white;
}
.pc-perfil .pc-perfil-avatar .pc-perfil--circle {
	border: 1px solid var(--cor-secundaria);
}
.pc-perfil .pc-perfil-avatar .pc-upload-button {
	background: var(--cor-secundaria);
}
.pc-perfil .pc-perfil-avatar .pc-upload-button:hover {
	background: var(--cor-primaria);
}
.pc-btnGhost.pc-btnGhost-primary svg path {
	fill: var(--cor-primaria);
}
.pc-btnGhost.pc-btnGhost-primary:hover svg path {
	fill: white;
}
.pc-btArquivo {
	background: var(--cor-primaria);
}
.pc-btArquivo:hover {
	background: var(--cor-secundaria);
}
.pc-infoUnidade ul li.active {
	background: var(--cor-primaria);
}
.pc-leftColumn {
	background: var(--cor-primaria);
}
@media (max-width: 575.98px) {
	.pc-menu-vertical .pc-topo {
		background: var(--cor-primaria);
	}
}
.pc-btnBox.pc-btnBox-branco {
	color: var(--cor-primaria);
}
.pc-btnBox.pc-btnBox-branco svg path {
	fill: var(--cor-primaria);
}
.pc-btnBox.pc-btnBox-branco:hover {
	background: var(--cor-primaria);
	color: #fff;
}
.pc-toast {
	background: var(--cor-primaria);
}
.pc-modalPopostaAtiva .pc-modalPopostaAtiva--proposta .pc-info .pc-footer h4 {
	color: var(--cor-secundaria);
}
.pc-itemUnidade .pc-info .pc-info--btns a:hover {
	background: var(--cor-secundaria);
}
.pc-tabela
	.pc-tabela--row
	.pc-tabela--td
	.pc-tabela--td-contentIcon
	.pc-tabela--td-contentIcon-icon.pc-tabela--td-contentIcon-data {
	background: var(--cor-primaria);
}
.box-mensagem.mensagem:before {
	background: var(--cor-secundaria);
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
	background: var(--cor-secundaria);
}

.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted.focus {
	background-color: var(--cor-primaria);
	border-color: var(--cor-primaria);
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.active,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted {
	color: #ffffff;
	background-color: var(--cor-primaria);
	border-color: var(--cor-primaria);
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
	background: var(--cor-secundaria);
}
.month:hover {
	color: var(--cor-secundaria);
}
.pc-banner p {
	background: var(--cor-primaria);
}
.pc-banner-sidebar p {
	background: var(--cor-primaria);
}
.pc-slide {
	position: relative;
	margin-bottom: 20px;
	background: white;
}
ul.hidden-links li a.active,
ul.hidden-links li a.router-link-active,
ul.hidden-links li a:hover {
	color: var(--cor-primaria);
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.pc-menu-vertical .pc-topo {
		background: var(--cor-primaria);
	}
}
@media (min-width: 768px) and (max-width: 991.98px) {
	.pc-menu-vertical .pc-topo {
		background: var(--cor-primaria);
	}
}
.disabled {
	opacity: 0.6;
	cursor: default;
	pointer-events: none;
}

.pc-slide-oportunidades .--slide .--content .--info p {
    color: var(--cor-secundaria) !important;
}
.pc-slide-oportunidades .slick-dots li.slick-active button {
    background-color: var(--cor-secundaria) !important;
}
.pc-slide-oportunidades .slick-dots li button:hover {
    background-color: var(--cor-secundaria) !important;
}
.pc-grafico-obra .--grafico .--mask .--percent{
	background: var(--cor-secundaria) !important;
}
</style>
