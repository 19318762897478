<template>
	<div class="pc-content">
		<BreadCrumbs :caminho="caminho" />
		<TituloPagina
			titulo="Minhas Assistências Técnicas"
			subtitulo="Relação de assistências técnicas da(s) sua(s) unidade(s)"
		>
			<router-link
				v-if="permissaoCriarAssistencia"
				:to="{ name: 'novaAssistencia' }"
				class="pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary"
			>Nova Assistência</router-link>
		</TituloPagina>
		<div class="vld-parent">
			<LoadingComponent :isLoading="isLoading" />

			<Aviso :exibir="aviso.exibir" :tipo="aviso.tipo" :mensagem="aviso.mensagem" />

			<div class="row">
				<div class="col-12">
					<div class="pc-bloco">
						<div class="pc-titleBloco">
							<div>
								<h3>Assistências Técnicas</h3>
							</div>
							<p
								class="pc-title-legenda"
								v-if="paginacao.total > 0"
							>Informações encontradas: {{ paginacao.total }}</p>
						</div>
						<div v-if="paginacao.total > 0">
							<div class="pc-tabela">
								<div class="pc-tabela--row pc-tabela--head">
									<div class="pc-tabela--td pc-f1">
										<p>Protocolo</p>
									</div>
                                    <div class="pc-tabela--td pc-f2">
										<p>Empreendimento</p>
									</div>
                                    <div class="pc-tabela--td pc-f1">
										<p>Bloco</p>
									</div>
									<div class="pc-tabela--td pc-f1" v-if="!acessoSindico">
										<p>Dados da unidade</p>
									</div>
                                    <div class="pc-tabela--td pc-f2">
                                        <p>Itens</p>
                                    </div>
                                    <div class="pc-tabela--td pc-f1">
                                        <p>Data de Cadastro</p>
                                    </div>
									<div class="pc-tabela--td pc-f1">
										<p>Situação atual</p>
									</div>
									<div class="pc-tabela--td pc-f1">
										<p>Opções</p>
									</div>
								</div>
								<Listagem
									v-for="assistencia in assistencias.assistencias"
									v-bind:key="assistencia.idassistencia"
									:assistencia="assistencia"
								/>
								<PaginateInput
									:total="paginacao.total"
									:limit="paginacao.limit"
									:offset="paginacao.offset"
									@paginacao="setarPagina"
								/>
							</div>
						</div>
						<div v-else>Nenhuma informação encontrada.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Listagem from "@/pages/assistencias/components/Listagem";
import { getAssistenciasTecnicas } from "@/services/AssistenciasService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";

export default {
	name: "MinhasAssistencias",

	components: {
		BreadCrumbs,
		Listagem,
		LoadingComponent,
		PaginateInput,
		TituloPagina,
		Aviso,
	},
	computed: {
		...mapState({
			unidade: (state) => state.unidades.unidade,
			unidades: (state) => state.unidades.unidades
		}),
		...mapGetters({
			verificarPermissao: "verificarPermissao",
			verificarPermissaoSindico: "verificarPermissaoSindico",			
			acessoSindico: "getAcessoSindico"
		}),
	},

	created() {
		this.$mixpanel.track(
			Vue.prototype.$eventoMixPanel,
			objetoTraking(
				"Minhas Assistências",
				"Minhas Assistências",
				"Listagem",
				true,
				false
			)
		);
		if (
				(!this.acessoSindico && (this.unidades == null || 
				this.verificarPermissao(this.unidade.perfil.sigla, "criar_assistencias"))) ||
				(this.acessoSindico && this.verificarPermissaoSindico("criar_assistencias"))
		) {
			this.permissaoCriarAssistencia = true;
		}
	},

	data() {
		return {
			caminho: [
				{
					texto: "Minhas Assistências",
					ativo: true,
					rota: null,
				},
			],
			assistencias: [],
			paginacao: {
				total: 0,
				offset: 0,
				limit: 30,
			},
			aviso: {
				exibir: false,
				tipo: null,
				mensagem: null,
			},
			isLoading: false,
			permissaoCriarAssistencia: false,
		};
	},

	beforeMount() {
		this.setarPagina(1);
	},

	methods: {
		setarPagina(pagina) {
			this.isLoading = true;
			getAssistenciasTecnicas(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit,
				null,
			)
				.then((response) => {
					this.assistencias = response.data;
					this.paginacao.total = response.data.total;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.tratarErro(error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		tratarErro(erro) {
			let mensagem =
				"Ocorreu um erro inesperado, por favor tente mais tarde.";
			if (erro.code != 422) {
				mensagem = erro.error;
			}

			this.aviso = {
				exibir: true,
				tipo: "erro",
				mensagem: mensagem,
			};
		},
	},
};
</script>
