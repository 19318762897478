var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-content"},[_c('BreadCrumbs',{attrs:{"caminho":_vm.caminho}}),_c('TituloPagina',{attrs:{"titulo":"Meus Atendimentos","subtitulo":"Relação de atendimentos da sua(s) unidade(s)"}},[(_vm.permissaoCriarAtendimento)?_c('router-link',{staticClass:"pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary",attrs:{"to":{ name: 'novoAtendimento' }}},[_vm._v(" Solicitar novo atendimento")]):_vm._e()],1),_c('div',{staticClass:"vld-parent"},[_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),_c('Aviso',{attrs:{"exibir":_vm.aviso.exibir,"tipo":_vm.aviso.tipo,"mensagem":_vm.aviso.mensagem}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pc-bloco"},[_c('div',{staticClass:"pc-titleBloco"},[_vm._m(0),(_vm.paginacao.total > 0)?_c('p',{staticClass:"pc-title-legenda"},[_vm._v("Informações encontradas: "+_vm._s(_vm.paginacao.total))]):_vm._e()]),(_vm.paginacao.total > 0)?_c('div',[_c('div',{staticClass:"pc-tabela"},[_c('div',{staticClass:"pc-tabela--row pc-tabela--head"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.acessoSindico)?_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Área comum")])]):_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Unidade(s)")])]),_vm._m(7),_vm._m(8)]),_vm._l((_vm.atendimentos.atendimentos),function(atendimento){return _c('Listagem',{key:atendimento.idatendimento,attrs:{"atendimento":atendimento}})}),_c('PaginateInput',{attrs:{"total":_vm.paginacao.total,"limit":_vm.paginacao.limit,"offset":_vm.paginacao.offset},on:{"paginacao":_vm.setarPagina}})],2)]):_c('div',[_vm._v("Nenhuma informação encontrada.")])])])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Atendimentos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Protocolo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Data de cadastro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Assunto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Subassunto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Empreendimento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Bloco")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Situação atual")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Visualizar")])])
}]

export { render, staticRenderFns }