<script setup>
import {onMounted, ref} from "vue";

const emit = defineEmits(['result']);
const getUserMediaSupported = ref(false);
const stream = ref(null);
const mediaRecorder = ref(null);
const elementoGravador = ref(null);

let chunks = [];
onMounted(() => {
    if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({audio: true})
            .then((stream) => {
                getUserMediaSupported.value = true;
                mediaRecorder.value = new MediaRecorder(stream);
                mediaRecorder.value.ondataavailable = (e) => {
                    chunks.push(e.data);
                };
                mediaRecorder.value.onstop = (e) => {
                    const blob = new Blob(chunks);
                    chunks = [];
                    emit("result", blob);
                };
            })
            .catch((err) => {
                console.error(err);
            })
        ;
    }
});

function startRecord() {
    mediaRecorder.value.start();
    elementoGravador.value.classList.add("active");
}

function stopRecord() {
    mediaRecorder.value.stop();
    elementoGravador.value.classList.remove("active");
}

function toggleRecord() {
    if (mediaRecorder.value.state === "recording") {
        stopRecord();
    } else {
        startRecord();
    }
}
</script>

<template>
    <div
        ref="elementoGravador"
        class="gravador-de-audio"
        v-if="getUserMediaSupported"
        @click="toggleRecord"
    >
        <span></span>
    </div>
</template>

<style scoped>
.gravador-de-audio {
    position: relative;
    background-color: #4db6ac;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: inline-block;
    cursor: pointer;
    -webkit-box-shadow: 0 0 0 0 rgba(232, 76, 61, .7);
    box-shadow: 0 0 0 0 rgba(232, 76, 61, .7)
}

.gravador-de-audio:hover {
    background-color: #26a69a
}

.gravador-de-audio.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s cubic-bezier(.66, 0, 0, 1) infinite;
    animation: pulse 1.25s cubic-bezier(.66, 0, 0, 1) infinite
}

.gravador-de-audio:after, .gravador-de-audio:before {
    content: "";
    position: absolute;
    background-color: #fff
}

.gravador-de-audio:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%
}

.gravador-de-audio:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%
}

.gravador-de-audio span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden
}

.gravador-de-audio span:after, .gravador-de-audio span:before {
    content: "";
    position: absolute;
    background-color: #fff
}

.gravador-de-audio span:before {
    bottom: 50%;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50%;
    border: .125em solid #fff;
    background: none;
    left: 0
}

.gravador-de-audio span:after {
    top: 50%;
    left: 40%;
    width: 20%;
    height: 25%
}

@-webkit-keyframes pulse {
    to {
        -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        background-color: #e53935;
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@keyframes pulse {
    to {
        -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        background-color: #e53935;
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}
</style>